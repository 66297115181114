import React, { useRef, useState } from "react";
import { ThreeEvent, useFrame } from "@react-three/fiber";
import { useSpring, animated } from "@react-spring/three";
import { Vector3, Mesh, Euler } from "three";

//GLOBAL STATE
import { ExperienceStates, useExperience } from "../stores/useExperience";
import { Callout, CalloutProps } from "./Callout";
import { Dimensions } from "../types";
import { convertCoordinates } from "../utils/blenderToThree";

const __DEBUG = false;

export interface IconProps {
  name: string;
  blenderPosition: Vector3;
  idleRotationSpeed: number;
  hoverTranslateY: number;
  hoverLookAtFactor: number;
  selectorDimensions: Dimensions;
  paddingLeaveDimensions: Dimensions;
  hoverRotationY: number;
  cameraPositionSelected: Vector3;
  cameraTargetSelected: Vector3;
  calloutProps: CalloutProps;
  children?: React.ReactNode;
}

export const Icon: React.FC<IconProps> = (props: IconProps) => {
  const convertedPosition = convertCoordinates(props.blenderPosition);

  //GLOBAL STATE
  const { experienceState, cameraPositionStart, cameraTargetPositionStart } =
    useExperience();
  const experienceHover = useExperience((state) => state.setHovered);
  const experienceUnhover = useExperience((state) => state.setUnhovered);
  const experienceSelected = useExperience((state) => state.setSelected);
  // const experienceExit = useExperience((state) => state.exit);

  //REFS
  const iconRef = useRef<Mesh>(null);
  const iconSelectorRef = useRef<Mesh>(null);
  const iconLeaveRef = useRef<Mesh>(null);
  const iconTranslatorRef = useRef<Mesh>(null);

  //ANIMATION USING SPRING
  const [springsActive, setSpringsActive] = useState(false);
  const springs = useSpring({
    scale: springsActive ? 1.5 : 1,
    translateY: springsActive
      ? new Vector3(
          convertedPosition.x,
          convertedPosition.y + props.hoverTranslateY,
          convertedPosition.z
        )
      : convertedPosition,
    rotation: springsActive ? [0, props.hoverRotationY, 0] : [0, 0, 0],
  });

  //HANDLERS
  const iconClickHandler = (event: ThreeEvent<MouseEvent>) => {
    if (experienceState === ExperienceStates.SELECTED) {
      return;
    }
    //PREVENTS FROM CLICKING THROUGH MULTIPLE OBJECTS
    event.stopPropagation();
    document.body.style.cursor = "default";

    //SET THE CAMERA POSITION AND TARGET BASED ON THE ICON POSITION
    const cameraPositionClicked: Vector3 | undefined =
      props.cameraPositionSelected;
    const cameraTargetClicked: Vector3 | undefined = props.cameraTargetSelected;

    //Scale back down the spring
    setSpringsActive(false);

    //SEND GLOBAL STATE
    if (cameraPositionClicked && cameraTargetClicked) {
      experienceSelected({
        cameraPosition: cameraPositionClicked,
        cameraTarget: cameraTargetClicked,
        selectedIcon: props.name.toLowerCase(),
      });
    }
  };

  //handler for when you hover over the icon (while wide view)
  const iconPointerEnterHandler = (event: ThreeEvent<PointerEvent>) => {
    if (experienceState === ExperienceStates.SELECTED) {
      return;
    }
    event.stopPropagation();
    document.body.style.cursor = "pointer";

    const iconPosition = iconRef.current && iconRef.current.position;

    const vectorOriginToDestination = cameraPositionStart
      .clone()
      .sub(iconPosition || new Vector3(0, 0, 0));
    let halfwayPoint = vectorOriginToDestination
      .clone()
      .divideScalar(props.hoverLookAtFactor);
    halfwayPoint = halfwayPoint.multiplyScalar(-1);
    experienceHover({
      cameraTarget: halfwayPoint,
    });
    setSpringsActive(true);
  };

  const iconPointerLeaveHandler = (event: ThreeEvent<PointerEvent>) => {
    if (experienceState === ExperienceStates.SELECTED) {
      return;
    }
    event.stopPropagation();
    document.body.style.cursor = "default";
    experienceUnhover({
      cameraPosition: cameraPositionStart,
      cameraTarget: cameraTargetPositionStart,
    });
    setSpringsActive(false);
  };

  useFrame((state, delta) => {
    if (!state) {
      console.error("Error getting useFrame state");
    }
    if (iconTranslatorRef.current) {
      iconTranslatorRef.current.rotation.y += delta * props.idleRotationSpeed;
    }
  });

  return (
    <group>
      {props.calloutProps && (
        <Callout {...props.calloutProps} active={springsActive} />
      )}
      <mesh
        name="Icon_Selector"
        ref={iconSelectorRef}
        position={convertedPosition}
        onClick={iconClickHandler}
        onPointerEnter={iconPointerEnterHandler}
        visible={__DEBUG}
        scale={1}
      >
        <boxGeometry args={props.selectorDimensions} />
        <meshBasicMaterial color="red" wireframe={true} />
      </mesh>
      <mesh
        name="Icon_Leave"
        ref={iconLeaveRef}
        position={convertedPosition}
        onPointerLeave={iconPointerLeaveHandler}
        visible={__DEBUG}
        scale={1}
      >
        <boxGeometry args={props.paddingLeaveDimensions} />
        <meshBasicMaterial color="orange" wireframe={true} />
      </mesh>
      <mesh
        name="Icon_Locator"
        ref={iconRef}
        position={convertedPosition}
        visible={__DEBUG}
        scale={0.2}
      >
        <boxGeometry args={props.selectorDimensions} />
        <meshBasicMaterial color="yellow" wireframe={true} />
      </mesh>
      <animated.mesh
        name="Icon_Translator"
        ref={iconTranslatorRef}
        position={springs.translateY}
        rotation={new Euler(...springs.rotation.get())}
        visible={true}
        scale={springs.scale}
      >
        {props.children}
      </animated.mesh>
    </group>
  );
};
