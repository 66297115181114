/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t --transform Petal.glb 
Files: Petal.glb [6.59KB] > /Users/nakaikoi/MOTIONTONIC Dropbox/CATBOTICA/CATBOTICA_EXPERIENCES/WEBSITE EXPERIENCE/UI/OUTPUTS/3JS/_Hold/Petal-transformed.glb [6.55KB] (1%)
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    WebExp_Petal: THREE.Mesh;
  };
  materials: {
    CatHQ_Petal: THREE.MeshStandardMaterial;
  };
};

export function Petal() {
  const { nodes, materials } = useGLTF(
    "/models/Petal-transformed.glb"
  ) as GLTFResult;
  return (
    <group dispose={null}>
      <mesh
        geometry={nodes.WebExp_Petal.geometry}
        material={materials.CatHQ_Petal}
        position={[-9.963, -3.128, -15.061]}
        rotation={[2.913, -0.571, 3.017]}
      />
      <mesh
        geometry={nodes.WebExp_Petal.geometry}
        material={materials.CatHQ_Petal}
        position={[-16.094348907470703, -3.1277294158935547, 9.695188522338867]}
        rotation={[0, -57.6* (Math.PI / 180), 0]}
      />
      <mesh
        geometry={nodes.WebExp_Petal.geometry}
        material={materials.CatHQ_Petal}
        position={[0.327540397644043, -2.7356607913970947, 16.211681365966797]}
        rotation={[0, 33.93 * (Math.PI / 180), 0]}
      />
      <mesh
        geometry={nodes.WebExp_Petal.geometry}
        material={materials.CatHQ_Petal}
        position={[15.542881965637207, -2.9064455032348633, -10.027156829833984 ]}
        rotation={[0, 123.39 * (Math.PI / 180), 0]}
      />
    </group>
  );
}

useGLTF.preload("/models/Petal-transformed.glb");
