import React from "react";
import { PageProps } from "../../types/page";
import { ExitButton } from "../../components/ExitButton";

export const GamesPage: React.FC<PageProps> = ({ onPageExit: onPageExit }) => {
  const title = "Games";

  return (
    <div className="page">
      <h1>{title}</h1>
      <ExitButton onPageExit={onPageExit} />
      <div className="page-deck">
        <div className="page-card">
          <p className="page-p">
            Customize your very own Catbot for download or print! <br />
            Then you can tell your friends, "That's my Catbot!!!"
          </p>
          <img className="page-img" src="/images/my-catbot-preview.png" />
          <h2>
            <a className="page-button" href="https://mycatbot.catbotica.com/">
              MyCatbot
            </a>
          </h2>
        </div>
        <div className="page-card">
          <p className="page-p">
            Can you beat the buzzer to match the configuration of randomly
            generated Catbots? <br />
            Test your memory and your speed at Match the Catbot!
          </p>
          <img className="page-img" src="/images/match-the-catbot.png" />
          <h2>
            <a
              className="page-button"
              href="https://matchthecatbot.catbotica.com/"
            >
              Match the Catbot
            </a>
          </h2>
        </div>
      </div>
    </div>
  );
};
