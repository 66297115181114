const fillColor = "#48ffeb";

const catboticaLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="100"
    viewBox="0 0 660.779 122.121"
    className="catbotica-logo-section-one"
  >
    <g
      id="catbotica_logo_semiwhite"
      transform="translate(0 -0.006)"
      opacity="1"
    >
      <g id="Layer_1" data-name="Layer 1" transform="translate(0 0.006)">
        <path
          id="Path_160"
          data-name="Path 160"
          d="M124.345,34.384q-1.385-2.439-2.78,0l-42.154,81.3c-.723,1.586-.4,2.489.953,2.7H94.848a4.828,4.828,0,0,0,2.429-.733c.2-.141.411-.3.622-.482.472-.341,1-.733,1.506-1.164q2.5-1.957,3.623-2.74a53.192,53.192,0,0,1,7.879-4.416q15.8-7.437,29.317-7.768l8.029,16.51a2.208,2.208,0,0,0,1.425.793h16.179c1.124-.141,1.5-.763,1.124-1.857Zm.522,54.7a98.687,98.687,0,0,0-17.584,6.564l14.493-30.11c.341-.642.683-.652,1,0l10.639,21.739a80.169,80.169,0,0,0-8.551,1.807Z"
          transform="translate(0.289 0.115)"
          fill={fillColor}
        ></path>
        <path
          id="Path_161"
          data-name="Path 161"
          d="M79.63,101.524v-.08c1-1.475.873-2.75-.351-3.8l-9.023-6.423q-2.63-1.726-4.908.883l-.09.08a30.37,30.37,0,0,1-8.652,7.357A25.945,25.945,0,0,1,45.165,102.8H43.479a28.838,28.838,0,0,1-3.643-.251l-3.172-.683a23.586,23.586,0,0,1-5.851-2.62,26.306,26.306,0,0,1-9.163-9.655,27.861,27.861,0,0,1-3.443-13.67,28.193,28.193,0,0,1,3.443-13.81,26.768,26.768,0,0,1,7.909-8.933c.4-.3.823-.592,1.255-.853A24.268,24.268,0,0,1,43.86,48.652h1.124a26.617,26.617,0,0,1,11.663,3.322,30.371,30.371,0,0,1,8.591,7.377l.733.833c1.094,1.245,2.509,1.285,4.266.13l9.033-6.464c1.224-1.054,1.345-2.328.351-3.8v-.08l-.1-.151a41.993,41.993,0,0,0-7.859-8.32,43.859,43.859,0,0,0-12.9-7.146,44.532,44.532,0,0,0-13.84-2.589H43.689A43.759,43.759,0,0,0,29.548,34.1a28.991,28.991,0,0,0-2.931,1.1A43.559,43.559,0,0,0,12.757,44.7,44.964,44.964,0,0,0,3.593,58.257c-.06.151-.12.3-.191.442A44.843,44.843,0,0,0,0,75.881,45.727,45.727,0,0,0,3.593,93.435a41.722,41.722,0,0,0,2.429,4.727A43.368,43.368,0,0,0,21.589,113.9a41.24,41.24,0,0,0,7.839,3.573l1.546.5a46.079,46.079,0,0,0,12.415,1.776h1.546a44.532,44.532,0,0,0,13.84-2.589,43.86,43.86,0,0,0,12.9-7.146,41.992,41.992,0,0,0,7.859-8.32Z"
          transform="translate(0 0.11)"
          fill={fillColor}
        ></path>
        <path
          id="Path_162"
          data-name="Path 162"
          d="M451.947,33.24H394.006a1.576,1.576,0,0,0-1.566,1.666V47.823a1.475,1.475,0,0,0,1.566,1.425h19.461V117.5c.1.612.652.933,1.676,1h14.463c.934,0,1.445-.321,1.546-.923V49.329h20.8a1.475,1.475,0,0,0,1.586-1.425v-13a1.576,1.576,0,0,0-1.586-1.666Z"
          transform="translate(1.436 0.115)"
          fill={fillColor}
        ></path>
        <path
          id="Path_163"
          data-name="Path 163"
          d="M215.007,33.24h-58a1.546,1.546,0,0,0-1.546,1.646V47.8a1.455,1.455,0,0,0,1.546,1.445h19.481v68.179c.1.632.652,1,1.646,1h14.5c.933,0,1.445-.331,1.546-.943V49.238h20.806a1.475,1.475,0,0,0,1.586-1.425V34.906a1.586,1.586,0,0,0-1.566-1.666Z"
          transform="translate(0.569 0.115)"
          fill={fillColor}
        ></path>
        <path
          id="Path_164"
          data-name="Path 164"
          d="M274.976,70.195a17.574,17.574,0,0,0,4.537-6.383,20.463,20.463,0,0,0,1.505-8.029q-.06-7.919-3.513-12.9a19.973,19.973,0,0,0-9.545-7.357,39.434,39.434,0,0,0-14.051-2.3H230.143q-3.011.381-3.483,3.673v78.085a3.6,3.6,0,0,0,3.322,3.412h26.376a35.129,35.129,0,0,0,14.8-3.232,27.591,27.591,0,0,0,11.04-9.384,25.986,25.986,0,0,0,4.346-14.844,23.587,23.587,0,0,0-2.911-11.492,26.958,26.958,0,0,0-8.662-9.254ZM244.335,49.248h8.882a12.335,12.335,0,0,1,6.654,1.526q2.83,1.606,2.941,6.353a10.276,10.276,0,0,1-.542,3.633,5.651,5.651,0,0,1-2.74,3.011,13.137,13.137,0,0,1-6.313,1.275h-8.882Zm21.077,50.183c-1.957,1.937-4.787,2.921-8.481,3.011h-12.6V80.452H256.9q5.54.06,8.481,3.011a10.939,10.939,0,0,1,3.011,8.15,10.338,10.338,0,0,1-3.011,7.818Z"
          transform="translate(0.829 0.115)"
          fill={fillColor}
        ></path>
        <path
          id="Path_165"
          data-name="Path 165"
          d="M658.32,115.751l-42.154-81.3c-.913-1.656-1.837-1.656-2.75,0l-42.686,82.17c-.371,1.094,0,1.716,1.114,1.857h16.169a2.157,2.157,0,0,0,1.425-.793l8.09-16.51q13.519.331,29.327,7.768a53.765,53.765,0,0,1,7.879,4.416q1.134.783,3.613,2.74l1.485,1.184.642.482a4.708,4.708,0,0,0,2.409.713h14.433Q659.4,118.12,658.32,115.751ZM612.884,89.084a80.678,80.678,0,0,0-8.571-1.837l10.619-21.739c.341-.622.693-.612,1,0l14.483,30.11a98.6,98.6,0,0,0-17.534-6.534Z"
          transform="translate(2.088 0.115)"
          fill={fillColor}
        ></path>
        <path
          id="Path_166"
          data-name="Path 166"
          d="M479.646,33.24H466.8a2.539,2.539,0,0,0-2.389,2.65v79.841a2.559,2.559,0,0,0,2.409,2.68h12.847a2.6,2.6,0,0,0,2.429-2.529V35.769A2.589,2.589,0,0,0,479.646,33.24Z"
          transform="translate(1.7 0.115)"
          fill={fillColor}
        ></path>
        <path
          id="Path_167"
          data-name="Path 167"
          d="M571.22,101.524v-.08c1-1.475.873-2.75-.351-3.8l-9.033-6.464q-2.63-1.726-4.908.883l-.09.08a30.373,30.373,0,0,1-8.631,7.357,25.945,25.945,0,0,1-11.412,3.3h-1.7a28.81,28.81,0,0,1-3.633-.251l-3.172-.683a23.816,23.816,0,0,1-5.9-2.62,26.365,26.365,0,0,1-9.153-9.655,27.753,27.753,0,0,1-3.443-13.67,28.1,28.1,0,0,1,3.443-13.81,26.848,26.848,0,0,1,7.869-8.933c.411-.3.823-.592,1.254-.853a24.329,24.329,0,0,1,13.048-3.673h1.124a26.658,26.658,0,0,1,11.673,3.342,30.369,30.369,0,0,1,8.651,7.357l.733.833q1.626,1.867,4.266.13l9.033-6.464c1.224-1.054,1.345-2.328.351-3.8v-.08l-.1-.151a42.594,42.594,0,0,0-7.879-8.32,43.991,43.991,0,0,0-12.9-7.146,44.534,44.534,0,0,0-13.84-2.589H535.29a43.859,43.859,0,0,0-14.182,2.318c-1,.321-1.957.693-2.921,1.1a43.45,43.45,0,0,0-13.86,9.495,43.981,43.981,0,0,0-12.767,31.2,44.582,44.582,0,0,0,6.022,22.281,43.4,43.4,0,0,0,15.6,15.737,40.958,40.958,0,0,0,7.839,3.573l1.546.5a46.028,46.028,0,0,0,12.415,1.776h1.546a44.532,44.532,0,0,0,13.84-2.589,43.994,43.994,0,0,0,12.9-7.166,42.593,42.593,0,0,0,7.859-8.32Z"
          transform="translate(1.799 0.11)"
          fill={fillColor}
        ></path>
        <path
          id="Path_168"
          data-name="Path 168"
          d="M397.861,76.77a2.248,2.248,0,0,0-1.776-.181l-9.133,2.75a45.831,45.831,0,0,0-.321-10.087A44.312,44.312,0,0,0,377.4,48.105c-.3-.361-.592-.713-.9-1.064a5.224,5.224,0,0,0-.141-.943l-4.416-23.014q-1.455-7.979-8.029-3.3L346.926,31.856a47.674,47.674,0,0,0-10.117.251l-3.352-18.066a7.025,7.025,0,0,0,2.87-2.349,6.885,6.885,0,0,0,1.355-5.41,7.266,7.266,0,0,0-8.3-6.193A7.275,7.275,0,0,0,323.2,8.4a7.076,7.076,0,0,0,2.921,4.787,6.935,6.935,0,0,0,4.014,1.445L333.487,32.7a45.256,45.256,0,0,0-11.753,4.436l-22.382-2.549q-7.949-1-5.46,6.584l7.026,21.549a44.642,44.642,0,0,0-1.445,19.461,44.09,44.09,0,0,0,5.922,16.49l.542.873-10.408,3.362a2.308,2.308,0,0,0-1.355,1.174,2.268,2.268,0,0,0-.151,1.837,2.238,2.238,0,0,0,1.174,1.365,2.359,2.359,0,0,0,1.817.11l11.853-3.844a44.441,44.441,0,0,0,4.426,4.687l-6.694,10.2a2.369,2.369,0,0,0-.371,1.776,2.319,2.319,0,0,0,1,1.546,2.559,2.559,0,0,0,1.8.331,2.368,2.368,0,0,0,1.505-1l6.484-9.766a43.671,43.671,0,0,0,15.055,7.176,44.161,44.161,0,0,0,17.574.873,43.955,43.955,0,0,0,32.86-23.907l11.683,4.617a2.168,2.168,0,0,0,1.817,0,2.369,2.369,0,0,0,1.275-1.295,2.278,2.278,0,0,0,0-1.817,2.339,2.339,0,0,0-1.315-1.275l-11.632-4.637a39.385,39.385,0,0,0,1.365-4.326c.181-.723.351-1.445.5-2.178l11.181-3.412a2.148,2.148,0,0,0,1.415-1.144,2.359,2.359,0,0,0-1-3.222Zm-34.676,7.839a21.8,21.8,0,0,1-6.393,9.033,21.067,21.067,0,0,1-30.23-3.593,23.376,23.376,0,0,1-4.516-10.85,23.085,23.085,0,0,1,1.174-11.6,21.93,21.93,0,0,1,6.353-9.113,20.315,20.315,0,0,1,10.268-4.6,20.585,20.585,0,0,1,11.221,1.425,21.879,21.879,0,0,1,8.732,6.855,22.976,22.976,0,0,1,4.536,10.759,23.476,23.476,0,0,1-1.144,11.683Z"
          transform="translate(1.073 -0.006)"
          fill={fillColor}
        ></path>
        <path
          id="Path_169"
          data-name="Path 169"
          d="M347.677,71.655a5.018,5.018,0,0,0-9.133-1.877,4.837,4.837,0,0,0-.773,3.784l2.238,11.683a5.044,5.044,0,0,0,9.906-1.907Z"
          transform="translate(1.236 0.241)"
          fill={fillColor}
        ></path>
        <path
          id="Path_170"
          data-name="Path 170"
          d="M640.858,35.645V46.274h-1.536V35.645H635.93V34.22h8.32v1.425ZM656.4,46.274V36.308L652.3,46.274h-.441l-4.105-9.966v9.966h-1.536V34.23h2.359l3.483,8.8,3.5-8.762h2.359V46.314Z"
          transform="translate(2.327 0.119)"
          fill={fillColor}
        ></path>
      </g>
    </g>
  </svg>
);

export const loadingScreen = (
  <div className="loading-screen">
    <div className="page-deck">
      {catboticaLogo}
      <img className="loading-gif" src="/images/catfish.gif" />
    </div>
  </div>
);
