import {
  useExperience,
  ExperienceStates,
  SelectedPageOptions,
} from "../stores/useExperience";
import React, { useEffect, useState, ReactElement, useCallback } from "react";
import { ExamplePage } from "../experience/ExamplePage";
import { BlogNewsPage } from "../experience/BlogNews/BlogNewsPage";
import { CatobitcaHqPage } from "../experience/CatboticaHQ/CatboticaHqPage";
import { CreatorsCornerPage } from "../experience/CreatorsCorner/CreatorsCornerPage";
import { DigitariumPage } from "../experience/Digitarium/DigitariumPage";
import { EventsPage } from "../experience/Events/EventsPage";
import { GamesPage } from "../experience/Games/GamesPage";
import { ReplicatsBuilderPage } from "../experience/ReplicatsBuilder/ReplicatsBuilderPage";
import { StoryPage } from "../experience/Story/StoryPage";

const PageScene: React.FC = () => {
  //GLOBAL STATES
  const { experienceState, selectedPage: pageState } = useExperience();
  const experienceExit = useExperience((state) => state.exit);

  const [selectedPage, setSelectedPage] = useState<ReactElement | null>(null);

  const pageExitFocusHandler = useCallback(
    (event: React.MouseEvent<HTMLImageElement | HTMLButtonElement>) => {
      event.preventDefault();
      if (experienceState !== ExperienceStates.EXITED) {
        experienceExit({});
      }
    },
    []
  );

  useEffect(() => {
    if (
      experienceState === ExperienceStates.SELECTED ||
      experienceState === ExperienceStates.EXITED
    ) {
      switch (pageState) {
        case SelectedPageOptions.EXAMPLE:
          setSelectedPage(
            <ExamplePage onPageExit={(e) => pageExitFocusHandler(e)} />
          );
          break;
        case SelectedPageOptions.BLOG_NEWS:
          setSelectedPage(
            <BlogNewsPage onPageExit={(e) => pageExitFocusHandler(e)} />
          );
          break;
        case SelectedPageOptions.CATBOTICA_HQ:
          setSelectedPage(
            <CatobitcaHqPage onPageExit={(e) => pageExitFocusHandler(e)} />
          );
          break;
        case SelectedPageOptions.CREATORS_CORNER:
          setSelectedPage(
            <CreatorsCornerPage onPageExit={(e) => pageExitFocusHandler(e)} />
          );
          break;
        case SelectedPageOptions.DIGITARIUM:
          setSelectedPage(
            <DigitariumPage onPageExit={(e) => pageExitFocusHandler(e)} />
          );
          break;
        case SelectedPageOptions.EVENTS:
          setSelectedPage(
            <EventsPage onPageExit={(e) => pageExitFocusHandler(e)} />
          );
          break;
        case SelectedPageOptions.GAMES:
          setSelectedPage(
            <GamesPage onPageExit={(e) => pageExitFocusHandler(e)} />
          );
          break;
        case SelectedPageOptions.REPLICATS_BUILDER:
          setSelectedPage(
            <ReplicatsBuilderPage onPageExit={(e) => pageExitFocusHandler(e)} />
          );
          break;
        case SelectedPageOptions.STORY:
          setSelectedPage(
            <StoryPage onPageExit={(e) => pageExitFocusHandler(e)} />
          );
          break;
        default:
          setSelectedPage(null);
          break;
      }
    }
  }, [experienceState, pageState, pageExitFocusHandler]);

  return (
    <>
      <div className="header">
        <img
          className="header-icon"
          src="/images/catbotica_icon.png"
          width="100"
          height="100"
          onClick={(e) => pageExitFocusHandler(e)}
          alt="Catbotica Logo"
        />
      </div>
      {selectedPage}
    </>
  );
};

export default PageScene;
