/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t --transform BottomPort.glb 
Files: BottomPort.glb [13.74KB] > /Users/nakaikoi/MOTIONTONIC Dropbox/CATBOTICA/CATBOTICA_EXPERIENCES/WEBSITE EXPERIENCE/UI/OUTPUTS/3JS/_Hold/BottomPort-transformed.glb [13.89KB] (-1%)
*/

import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import {
  Mesh,
  MeshNormalMaterial,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
} from "three";

type GLTFResult = GLTF & {
  nodes: {
    WebExp_BottomPort: Mesh;
  };
  materials: {
    ["CatHQ_Bottom Port"]: MeshStandardMaterial;
  };
};

export function BottomPort() {
  const { nodes } = useGLTF("/models/BottomPort-transformed.glb") as GLTFResult;

  const materialsGenArc = {
    Back_01: new MeshStandardMaterial({
      color: 0x000000,
      metalness: 0,
      roughness: 0.5,
      transparent: true,
      opacity: 0.5,
    }),
    Normal: new MeshNormalMaterial({}),
    Physical: new MeshPhysicalMaterial({
      color: 0x808080,
      metalness: 0,
      roughness: 0.5,
      transparent: false,
      opacity: 1,
    }),
  };
  return (
    <group dispose={null}>
      <mesh
        geometry={nodes.WebExp_BottomPort.geometry}
        material={materialsGenArc.Physical}
        position={[3.852, -15.565, 0.576]}
        rotation={[0, -0.005, 0]}
      />
    </group>
  );
}

useGLTF.preload("/models/BottomPort-transformed.glb");
