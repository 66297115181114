/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t --transform Tower.glb 
Files: Tower.glb [3.52KB] > /Users/nakaikoi/MOTIONTONIC Dropbox/CATBOTICA/CATBOTICA_EXPERIENCES/WEBSITE EXPERIENCE/UI/OUTPUTS/3JS/_Hold/Tower-transformed.glb [3.6KB] (-2%)
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    WebExp_Tower: THREE.Mesh;
  };
  materials: {
    CatHQ_Tower: THREE.MeshStandardMaterial;
  };
};

export function Tower() {
  const { nodes, materials } = useGLTF(
    "/models/Tower-transformed.glb"
  ) as GLTFResult;
  return (
    <group dispose={null}>
      <mesh
        geometry={nodes.WebExp_Tower.geometry}
        material={materials.CatHQ_Tower}
        position={[-24.890575408935547, -5.456849098205566, -0.13123035430908203]}
        rotation={[0, -0.199, 0]}
        scale={0.941}
      />
      <mesh
        geometry={nodes.WebExp_Tower.geometry}
        material={materials.CatHQ_Tower}
        position={[0.013818740844726562, -5.456849098205566, 24.905235290527344]}
        rotation={[0, -0.199, 0]}
        scale={0.941}
      />
      <mesh
        geometry={nodes.WebExp_Tower.geometry}
        material={materials.CatHQ_Tower}
        position={[24.787824630737305, -5.456849098205566, 0.0008411407470703125]}
        rotation={[0, -0.199, 0]}
        scale={0.941}
      />
      <mesh
        geometry={nodes.WebExp_Tower.geometry}
        material={materials.CatHQ_Tower}
        position={[0.013818740844726562, -5.456849098205566, -24.903553009033203]}
        rotation={[0, -0.199, 0]}
        scale={0.941}
      />
    </group>
  );
}

useGLTF.preload("/models/Tower-transformed.glb");
