/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t --transform CatboticaHQ.glb 
Files: CatboticaHQ.glb [18.55KB] > C:\Users\fedde\Downloads\v2\CatboticaHQ-transformed.glb [18.67KB] (-1%)
*/

import { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { Icon, IconProps } from "../../components/Icon";
import { CalloutProps } from "../../components/Callout";
import { Euler, Mesh, MeshStandardMaterial, Vector3 } from "three";

type GLTFResult = GLTF & {
  nodes: {
    WebExp_CatboticaHQ: Mesh;
  };
  materials: {
    CatHQ_CatboticaHQ: MeshStandardMaterial;
  };
};

export function CatboticaHQ() {
  const normalizedPosition = new Vector3(0, 0, 0);
  const normalizedRotation = new Euler(0, 0, 0);
  const calloutProps: CalloutProps = {
    graphicTexturePath: "/images/IconGraphic.png",
    graphicGlb: "/models/Callout/CalloutPlanev02.glb",
    graphicLocation: new Vector3(-0.59, -0.1, 1.1),
    textTitle: "Catbotica HQ",
    textTitleFont: "/fonts/bebas-neue-v9-latin-regular.woff",
    textTitleColor: "#ffffff",
    textTitleFontSize: 0.4,
    textTitlePosition: new Vector3(-0.6, 0, 1),
    textSubTitle: "Headquarters",
    textSubTitleFontSize: 0.2,
    textSubTitleFont: "/fonts/bebas-neue-v9-latin-regular.woff",
    textSubTitleColor: "#00EAFF",
    textSubTitlePosition: new Vector3(-0.61, -0.4, 1.21),
    scaleSpeed: 5,
    pivotPoint: new Vector3(8.0058, 12.77771, -31.8888),
    active: false,
  };
  const iconProps: IconProps = {
    name: "Catbotica HQ",
    blenderPosition: new Vector3(38.0058, 31.8888, 6.77771),
    idleRotationSpeed: 0.5,
    hoverTranslateY: 5,
    hoverLookAtFactor: 5,
    selectorDimensions: [8, 12, 8],
    paddingLeaveDimensions: [20, 20, 20],
    hoverRotationY: 4,
    cameraPositionSelected: new Vector3(38.0058, 6.77771, -1.8888),
    cameraTargetSelected: new Vector3(38.0058, 6.77771, -31.8888),
    calloutProps: calloutProps,
  };
  //REFS
  const catboticaHqRef = useRef(null);
  const { nodes, materials } = useGLTF(
    "/models/CatboticaHQ-transformed.glb"
  ) as GLTFResult;
  return (
    <Icon
      name={iconProps.name}
      blenderPosition={iconProps.blenderPosition}
      idleRotationSpeed={iconProps.idleRotationSpeed}
      hoverTranslateY={iconProps.hoverTranslateY}
      hoverLookAtFactor={iconProps.hoverLookAtFactor}
      selectorDimensions={iconProps.selectorDimensions}
      paddingLeaveDimensions={iconProps.paddingLeaveDimensions}
      hoverRotationY={iconProps.hoverRotationY}
      cameraPositionSelected={iconProps.cameraPositionSelected}
      cameraTargetSelected={iconProps.cameraTargetSelected}
      calloutProps={calloutProps}
    >
      <group
        ref={catboticaHqRef}
        dispose={null}
        position={normalizedPosition}
        rotation={normalizedRotation}
        scale={1}
      >
        <mesh
          geometry={nodes.WebExp_CatboticaHQ.geometry}
          material={materials.CatHQ_CatboticaHQ}
        />
      </group>
    </Icon>
  );
}

useGLTF.preload("/models/CatboticaHQ-transformed.glb");
