/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t --transform Events.glb 
Files: Events.glb [5.32KB] > C:\Users\fedde\Downloads\v2\Events-transformed.glb [5.26KB] (1%)
*/

import { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { Mesh, MeshStandardMaterial, Vector3 } from "three";
import { CalloutProps } from "../../components/Callout";
import { Icon, IconProps } from "../../components/Icon";

type GLTFResult = GLTF & {
  nodes: {
    WebExp_Events: Mesh;
  };
  materials: {
    WebExp_Events: MeshStandardMaterial;
  };
};

export function EventsIcon() {
  const calloutProps: CalloutProps = {
    graphicTexturePath: "/images/IconGraphic.png",
    graphicGlb: "/models/Callout/CalloutPlanev02.glb",
    graphicLocation: new Vector3(-0.59, -0.1, 1.1),
    textTitle: "Events",
    textTitleFont: "/fonts/bebas-neue-v9-latin-regular.woff",
    textTitleColor: "#ffffff",
    textTitleFontSize: 0.4,
    textTitlePosition: new Vector3(-0.6, 0, 1),
    textSubTitle: "Upcoming",
    textSubTitleFontSize: 0.2,
    textSubTitleFont: "/fonts/bebas-neue-v9-latin-regular.woff",
    textSubTitleColor: "#00EAFF",
    textSubTitlePosition: new Vector3(-0.61, -0.4, 1.21),
    scaleSpeed: 5,
    pivotPoint: new Vector3(-35.5349, -11.7063, 3.9081),
    active: false,
  };
  const iconProps: IconProps = {
    name: "Events",
    blenderPosition: new Vector3(-35.5349, -3.9081, -11.7063),
    idleRotationSpeed: 0.5,
    hoverTranslateY: 5,
    hoverLookAtFactor: 5,
    selectorDimensions: [8, 12, 8],
    paddingLeaveDimensions: [15, 15, 15],
    hoverRotationY: 4,
    cameraPositionSelected: new Vector3(-35.5349, 0.9081, -20.7063),
    cameraTargetSelected: new Vector3(-35.5349, -11.7063, 3.9081),
    calloutProps: calloutProps,
  };

  //REFS
  const eventsRef = useRef(null);
  const { nodes, materials } = useGLTF(
    "/models/Events-transformed.glb"
  ) as GLTFResult;
  return (
    <Icon
      name={iconProps.name}
      blenderPosition={iconProps.blenderPosition}
      idleRotationSpeed={iconProps.idleRotationSpeed}
      hoverTranslateY={iconProps.hoverTranslateY}
      hoverLookAtFactor={iconProps.hoverLookAtFactor}
      selectorDimensions={iconProps.selectorDimensions}
      paddingLeaveDimensions={iconProps.paddingLeaveDimensions}
      hoverRotationY={iconProps.hoverRotationY}
      cameraPositionSelected={iconProps.cameraPositionSelected}
      cameraTargetSelected={iconProps.cameraTargetSelected}
      calloutProps={calloutProps}
    >
      <group ref={eventsRef} scale={1}>
        <mesh
          geometry={nodes.WebExp_Events.geometry}
          material={materials.WebExp_Events}
          rotation={[Math.PI / 2, 0, 0]}
        />
      </group>
    </Icon>
  );
}

useGLTF.preload("/models/Events-transformed.glb");
