/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t Earth_comp.glb 
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    EARTH: THREE.Mesh;
  };
  materials: {
    MI_BGM_Surface_MasterInstance: THREE.MeshStandardMaterial;
  };
};

export function Earth() {
  const { nodes, materials } = useGLTF(
    "/models/Earth/Earth_comp.glb"
  ) as GLTFResult;
  return (
    <group dispose={null}>
      <mesh
        geometry={nodes.EARTH.geometry}
        material={materials.MI_BGM_Surface_MasterInstance}
        position={[409.826, -307.44, -220.065]}
        rotation={[-0.003, 1.146, -0.209]}
        scale={0.095}
      />
    </group>
  );
}

useGLTF.preload("/models/Earth/Earth_comp.glb");
