/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t --transform ReplicatsBuilder.glb 
Files: ReplicatsBuilder.glb [77.64KB] > C:\Users\fedde\Downloads\v2\ReplicatsBuilder-transformed.glb [77.6KB] (0%)
*/

import { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import {
  Euler,
  Mesh,
  MeshNormalMaterial,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  Vector3,
} from "three";
import { Icon, IconProps } from "../../components/Icon";
import { CalloutProps } from "../../components/Callout";

type GLTFResult = GLTF & {
  nodes: {
    WebExp_ReplicatsBuilder: Mesh;
  };
  materials: {
    Back_01: MeshStandardMaterial;
  };
};

export function ReplicatsBuilder() {
  const normalizedPosition = new Vector3(0, 0, 0);
  const normalizedRotation = new Euler(0, 0, 0);
  const calloutProps: CalloutProps = {
    graphicTexturePath: "/images/IconGraphic.png",
    graphicGlb: "/models/Callout/CalloutPlanev02.glb",
    graphicLocation: new Vector3(-0.59, -0.1, 1.1),
    textTitle: "Replicats Builder",
    textTitleFont: "/fonts/bebas-neue-v9-latin-regular.woff",
    textTitleColor: "#ffffff",
    textTitleFontSize: 0.4,
    textTitlePosition: new Vector3(-0.6, 0, 1),
    textSubTitle: "3D Replicats",
    textSubTitleFontSize: 0.2,
    textSubTitleFont: "/fonts/bebas-neue-v9-latin-regular.woff",
    textSubTitleColor: "#00EAFF",
    textSubTitlePosition: new Vector3(-0.61, -0.4, 1.21),
    scaleSpeed: 5,
    pivotPoint: new Vector3(-4.66944, 4.84248, 43.9893),
    active: false,
  };
  const iconProps: IconProps = {
    name: "Replicats Builder",
    blenderPosition: new Vector3(-4.66944, -43.9893, 4.84248),
    idleRotationSpeed: 0.5,
    hoverTranslateY: 5,
    hoverLookAtFactor: 5,
    selectorDimensions: [8, 12, 8],
    paddingLeaveDimensions: [20, 20, 20],
    hoverRotationY: 4,
    cameraPositionSelected: new Vector3(-4.66944, 4.84248, 70.9893),
    cameraTargetSelected: new Vector3(-4.66944, 4.84248, 43.9893),
    calloutProps: calloutProps,
  };

  //REFS
  const replicatsBuilderRef = useRef(null);
  const { nodes } = useGLTF(
    "/models/ReplicatsBuilder-transformed.glb"
  ) as GLTFResult;

  const materialsGenArc = {
    Back_01: new MeshStandardMaterial({
      color: 0x000000,
      metalness: 0,
      roughness: 0.5,
      transparent: true,
      opacity: 0.5,
    }),
    Normal: new MeshNormalMaterial({}),
    Physical: new MeshPhysicalMaterial({
      color: 0x808080,
      metalness: 0,
      roughness: 0.5,
      transparent: false,
      opacity: 1,
    }),
  };
  return (
    <Icon
      name={iconProps.name}
      blenderPosition={iconProps.blenderPosition}
      idleRotationSpeed={iconProps.idleRotationSpeed}
      hoverTranslateY={iconProps.hoverTranslateY}
      hoverLookAtFactor={iconProps.hoverLookAtFactor}
      selectorDimensions={iconProps.selectorDimensions}
      paddingLeaveDimensions={iconProps.paddingLeaveDimensions}
      hoverRotationY={iconProps.hoverRotationY}
      cameraPositionSelected={iconProps.cameraPositionSelected}
      cameraTargetSelected={iconProps.cameraTargetSelected}
      calloutProps={calloutProps}
    >
      <group
        ref={replicatsBuilderRef}
        position={normalizedPosition}
        rotation={normalizedRotation}
        scale={1}
      >
        <mesh
          geometry={nodes.WebExp_ReplicatsBuilder.geometry}
          // material={materials.Back_01}
          material={materialsGenArc.Physical}
          rotation={[Math.PI, 0, Math.PI]}
        />
      </group>
    </Icon>
  );
}

useGLTF.preload("/models/ReplicatsBuilder-transformed.glb");
