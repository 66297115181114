//3JS React Drei
import { Perf } from "r3f-perf";
import { Sparkles } from "@react-three/drei";
import {
  Bloom,
  Vignette,
  EffectComposer,
  DepthOfField,
} from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";

//Custom GLB Components
import { Camera } from "../components/Camera.tsx";
import { Earth } from "./_static/Earth.tsx";
import { Skybox } from "./_static/Skybox.tsx";
import { Arm } from "./_static/Arm.tsx";
import { BottomPort } from "./_static/BottomPort.tsx";
import { CenterPlate } from "./_static/CenterPlate.tsx";
import { Petal } from "./_static/Petal.tsx";
import { Tower } from "./_static/Tower.tsx";
import { BlogNews } from "./BlogNews/BlogNews.tsx";

//CUSTOM GLOBAL STATE COMPONENTS
/*
NOTE: if any of these values change, 
the components using these global states will re-render, 
which is why its better to only select the data
that you truly need within the component
*/
import { CatboticaHQ } from "./CatboticaHQ/CatboticaHQ.tsx";
import { CreatorsCorner } from "./CreatorsCorner/CreatorsCorner.tsx";
import { EventsIcon } from "./Events/EventsIcon.tsx";
import { Games } from "./Games/Games.tsx";
import { ReplicatsBuilder } from "./ReplicatsBuilder/ReplicatsBuilder.tsx";
import { StoryIcon } from "./Story/StoryIcon.tsx";
import { Digitarium } from "./Digitarium/Digitarium.tsx";
import { ExperienceStates, useExperience } from "../stores/useExperience.ts";
import { useEffect, useState } from "react";

export function Experience() {
  const { experienceState } = useExperience();
  const [isIconSelected, setIsIconSelected] = useState(false);
  useEffect(() => {
    setIsIconSelected(
      experienceState === ExperienceStates.SELECTED ? true : false
    );
  }, [experienceState]);
  return (
    <>
      <Perf position={"top-left"} />
      {/* CONTROLS -------------------------------*/}

      {/* CAMERA--------------------------------------------- */}
      <Camera />

      {/* POST PROCESSING--------------------------------------------- */}
      <EffectComposer enableNormalPass={false} multisampling={1}>
        <Vignette
          offset={0.1}
          darkness={isIconSelected ? 1.1 : 0.9}
          blendFunction={BlendFunction.DARKEN}
        />

        {/* <Noise premultiply blendFunction={BlendFunction.SCREEN} /> */}
        <Bloom
          luminanceThreshold={0}
          luminanceSmoothing={0.9}
          height={300}
          mipmapBlur
        />

        <DepthOfField
          focusDistance={0}
          focalLength={isIconSelected ? 0.001 : 0}
          bokehScale={isIconSelected ? 10 : 0}
          height={480}
        />
      </EffectComposer>

      {/* ENVIRONMENT--------------------------------------------- */}
      <Skybox />

      <directionalLight intensity={0} />

      <color args={["#000"]} attach="background" />

      {/* COMPONENT ASSETS--------------------------------------------- */}

      <Earth />
      {/* <ExampleIcon /> */}
      <Arm />
      <BottomPort />
      <CenterPlate />
      <Petal />
      <Tower />
      <BlogNews />
      <CatboticaHQ />
      <CreatorsCorner />
      <EventsIcon />
      <Games />
      <ReplicatsBuilder />
      <StoryIcon />
      <Digitarium />

      {/* COMPONENT FX--------------------------------------------- */}
      <Sparkles
        size={12}
        scale={[40, 40, 40]}
        position-y={1}
        speed={0.2}
        count={200}
        color={"grey"}
      />
    </>
  );
}
