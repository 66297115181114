import { useTexture, Text, useGLTF } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/three";
import { Euler, MathUtils, Mesh, Vector3 } from "three";
import { Dimensions } from "../types";

const __DEBUG = true;

export interface CalloutProps {
  graphicTexturePath: string;
  graphicGlb: string; // Specify a more precise type if available
  graphicLocation: Vector3;
  orientation?: Vector3; // Assuming this is an array of numbers, adjust if different
  scale?: number;
  textTitle: string;
  textTitleFont: string;
  textTitleFontSize: number;
  textTitleColor: string;
  textTitlePosition: Vector3;
  textSubTitle: string;
  textSubTitleFont: string;
  textSubTitleFontSize: number;
  textSubTitleColor: string;
  textSubTitlePosition: Vector3;
  scaleSpeed: number;
  pivotPoint: Vector3;
  active: boolean;
}

export const Callout: React.FC<CalloutProps> = (props: CalloutProps) => {
  const [showCallout, setShowCallout] = useState(false);
  useEffect(() => {
    setShowCallout(props.active);
  }, [props.active]);

  const graphicNormalizedRotation = new Euler(
    MathUtils.degToRad(0),
    MathUtils.degToRad(-180),
    MathUtils.degToRad(0)
  );
  const textNormalizedRotation = new Euler(
    MathUtils.degToRad(0),
    MathUtils.degToRad(-90),
    MathUtils.degToRad(0)
  );
  const pivotPointerDimensions: Dimensions = [0.1, 0.1, 0.11];

  const texture = useTexture(props.graphicTexturePath);
  texture.flipY = false;
  const { nodes } = useGLTF(props.graphicGlb);
  const calloutTranslatorRef = useRef(null);

  //SPRINGS
  const springs = useSpring({
    scale: showCallout ? 10 : 0,
  });

  return (
    <>
      {/* Debug Geo */}
      <mesh visible={__DEBUG}>
        <boxGeometry args={[5, 5, 5]} />
        <meshBasicMaterial color="blue" wireframe={true} />
      </mesh>

      {/* 3d TEXT */}

      {/* Animated Texture */}
      <animated.mesh
        ref={calloutTranslatorRef}
        name="pivotPointer"
        position={props.pivotPoint}
        scale={springs.scale}
      >
        <Text
          font={props.textTitleFont}
          fontSize={props.textTitleFontSize}
          textAlign="left"
          // fontSize={20}
          color={props.textTitleColor}
          position={props.textTitlePosition}
          rotation={textNormalizedRotation}
        >
          {props.textTitle}
        </Text>
        <Text
          font={props.textTitleFont}
          fontSize={props.textSubTitleFontSize}
          textAlign="left"
          color={props.textSubTitleColor}
          position={props.textSubTitlePosition}
          rotation={textNormalizedRotation}
        >
          {props.textSubTitle}
        </Text>
        <boxGeometry args={pivotPointerDimensions} />
        <meshBasicMaterial color="pink" wireframe={true} />
        <mesh
          geometry={(nodes["grid1"] as Mesh).geometry}
          position={props.graphicLocation}
          rotation={graphicNormalizedRotation}
        >
          <meshBasicMaterial
            map={texture}
            wireframe={false}
            alphaMap={texture}
            transparent={true}
          />
        </mesh>
      </animated.mesh>
    </>
  );
};
