import React from "react";
import { PageProps } from "../types/page";
import { ExitButton } from "../components/ExitButton";

export const ExamplePage: React.FC<PageProps> = ({
  onPageExit: onPageExit,
}) => {
  const title = "Example";
  const video = "https://www.youtube.com/embed/iwvbObmkbOM?si=-jbOPQYNY5krHy1X";
  const videoWidth = String(560 / 2);
  const videoHeight = String(315 / 2);

  return (
    <div className="page">
      <h1>{title}</h1>
      <ExitButton onPageExit={onPageExit} />
      <iframe
        width={videoWidth}
        height={videoHeight}
        src={video}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};
