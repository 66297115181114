/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t --transform CenterPlate.glb 
Files: CenterPlate.glb [11.37KB] > /Users/nakaikoi/MOTIONTONIC Dropbox/CATBOTICA/CATBOTICA_EXPERIENCES/WEBSITE EXPERIENCE/UI/OUTPUTS/3JS/_Hold/CenterPlate-transformed.glb [11.56KB] (-2%)
*/

import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import {
  Mesh,
  MeshNormalMaterial,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
} from "three";

type GLTFResult = GLTF & {
  nodes: {
    WebExp_CenterPlate: Mesh;
  };
  materials: {
    ["CatHQ_Center Plate"]: MeshStandardMaterial;
  };
};

export function CenterPlate() {
  const { nodes } = useGLTF(
    "/models/CenterPlate-transformed.glb"
  ) as GLTFResult;

  const materialsGenArc = {
    Back_01: new MeshStandardMaterial({
      color: 0x000000,
      metalness: 0,
      roughness: 0.5,
      transparent: true,
      opacity: 0.5,
    }),
    Normal: new MeshNormalMaterial({}),
    Physical: new MeshPhysicalMaterial({
      color: 0x808080,
      metalness: 0,
      roughness: 0.5,
      transparent: false,
      opacity: 1,
    }),
  };
  return (
    <group dispose={null}>
      <mesh
        geometry={nodes.WebExp_CenterPlate.geometry}
        material={materialsGenArc.Physical}
        position={[-0.003, -4.139, 0.004]}
      />
    </group>
  );
}

useGLTF.preload("/models/CenterPlate-transformed.glb");
