import React from "react";
import { PageProps } from "../../types/page";
import { ExitButton } from "../../components/ExitButton";

export const StoryPage: React.FC<PageProps> = ({ onPageExit: onPageExit }) => {
  const title = "Story";

  return (
    <div className="page">
      <h1>{title}</h1>
      <ExitButton onPageExit={onPageExit} />
      <div className="page-deck">
        <div className="page-card">
          <h2>Coming Soon ...</h2>
        </div>
      </div>
    </div>
  );
};
