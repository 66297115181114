import { Ref, useEffect, useRef, useState } from "react";
import {
  useGLTF,
  PerspectiveCamera,
  useAnimations,
  OrbitControls,
} from "@react-three/drei";
import {
  Group,
  Object3DEventMap,
  Vector3,
  PerspectiveCamera as ThreePerspectiveCamera,
} from "three";
import { useFrame } from "@react-three/fiber";

//GLOBAL STATE
import { useExperience } from "../stores/useExperience";

// const __DEBUG = process.env.NODE_ENV !== "production";

export function Camera() {
  //GLOBAL STATE
  const { experienceState, cameraPosition, cameraTarget } = useExperience();
  //REFS
  const cameraRef =
    useRef<ThreePerspectiveCamera>() as React.MutableRefObject<ThreePerspectiveCamera>;

  //GLB
  const { animations } = useGLTF("/models/Camera/Camera_v01.glb");
  const { ref } = useAnimations(animations);
  const animationRef = ref as Ref<Group<Object3DEventMap>>;

  //FOCAL LENGTH CALCULATIONS
  const HEIGHT = 24; // Assuming a 35mm film
  const focalLength = 35; // The desired focal length
  // Calculate the fov based on the desired focal length
  const fov = 2 * Math.atan(0.5 / (focalLength / HEIGHT)) * (180 / Math.PI);

  //CAMERA POSITION AND TARGET
  const [smoothedCameraPosition] = useState(() => new Vector3());
  const [smoothedCameraTarget] = useState(() => new Vector3());

  //USE EFFECT updates on experienceState change
  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.position.copy(cameraPosition);
      cameraRef.current.lookAt(cameraTarget);
      cameraRef.current.updateProjectionMatrix();
    }
  }, [experienceState, cameraPosition, cameraTarget]);

  //UPDATE THE CAMERA POSITION AND TARGET ANIMATION
  useFrame((state, delta) => {
    smoothedCameraPosition.lerp(cameraPosition, 3 * delta);
    smoothedCameraTarget.lerp(cameraTarget, 3 * delta);

    state.camera.position.copy(smoothedCameraPosition);
    state.camera.lookAt(smoothedCameraTarget);
  });

  return (
    <group name="Camera_HQ" ref={animationRef} dispose={null}>
      <PerspectiveCamera
        ref={cameraRef}
        name="CineCameraActor"
        makeDefault={true}
        far={100000}
        near={0.1}
        fov={fov}
        aspect={1}
        position={cameraPosition}
      />
      <OrbitControls enablePan={true} enableZoom={true} enableRotate={true} />
    </group>
  );
}

useGLTF.preload("/models/Camera/Camera_v01.glb");
