import { create } from "zustand";
import { Vector3 } from "three";

export enum ExperienceStates {
  READY = "ready",
  BROWSING = "browsing",
  HOVERED = "hovered",
  UNHOVERED = "unhovered",
  SELECTED = "selected",
  EXITED = "exited",
}

export enum SelectedPageOptions {
  NONE = "none",
  EXAMPLE = "example",
  BLOG_NEWS = "blog news",
  CATBOTICA_HQ = "catbotica hq",
  CREATORS_CORNER = "creators corner",
  DIGITARIUM = "digitarium",
  EVENTS = "events",
  GAMES = "games",
  REPLICATS_BUILDER = "replicats builder",
  STORY = "story",
}

export const experienceContexts = {
  wide: "wide",
  port: "port",
};

interface StoreState {
  experienceState: ExperienceStates;
  selectedPage: SelectedPageOptions;
  cameraTarget: Vector3;
  cameraPosition: Vector3;
  cameraPositionStart: Vector3;
  cameraTargetPositionStart: Vector3;
}

interface StoreActionParams {
  cameraPosition?: Vector3 | undefined;
  cameraTarget?: Vector3;
  selectedIcon?: string;
}

export interface StoreActions {
  setHovered: (params: StoreActionParams) => void;
  setUnhovered: (params: StoreActionParams) => void;
  setSelected: (params: StoreActionParams) => void;
  exit: (params: StoreActionParams) => void;
}

type Store = StoreState & StoreActions;

const __DEBUG = process.env.NODE_ENV !== "production";

const _cameraPosition: Vector3 = new Vector3(-191, 81, 3.5);
const _targetPosition: Vector3 = new Vector3(0, 0, 0);

export const useExperience = create<Store>((set) => ({
  experienceState: ExperienceStates.READY,
  selectedPage: SelectedPageOptions.NONE,
  //PHASES
  cameraPosition: _cameraPosition,
  cameraTarget: _targetPosition,
  cameraPositionStart: _cameraPosition,
  cameraTargetPositionStart: _targetPosition,

  setHovered: ({ cameraTarget }) => {
    set((state) => {
      if (state.experienceState === ExperienceStates.SELECTED) {
        return {};
      }
      return {
        experienceState: ExperienceStates.HOVERED,
        cameraTarget,
      };
    });
  },
  setUnhovered: ({ cameraPosition, cameraTarget }) => {
    set((state) => {
      if (state.experienceState === ExperienceStates.SELECTED) {
        return {};
      }
      return {
        experienceState: ExperienceStates.UNHOVERED,
        cameraPosition,
        cameraTarget,
      };
    });
  },
  setSelected: ({ cameraPosition, cameraTarget, selectedIcon = "NONE" }) => {
    set(() => {
      if (__DEBUG) {
        console.log("selectedPage for: ", selectedIcon);
      }
      let selectedPage: SelectedPageOptions;
      if (
        Object.values(SelectedPageOptions).includes(
          selectedIcon as SelectedPageOptions
        )
      ) {
        selectedPage = selectedIcon as SelectedPageOptions;
      } else {
        // Handle the case where selectedIcon is not a valid enum value
        selectedPage = SelectedPageOptions.NONE; // Default or error handling
      }
      return {
        experienceState: ExperienceStates.SELECTED,
        cameraPosition,
        cameraTarget,
        selectedPage,
      };
    });
  },
  exit: () => {
    set(() => {
      return {
        experienceState: ExperienceStates.EXITED,
        cameraPosition: _cameraPosition,
        cameraTarget: _targetPosition,
        selectedPage: SelectedPageOptions.NONE,
      };
    });
  },
}));
