/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t --transform Skybox-transformed.glb 
Files: Skybox-transformed.glb [4.31KB] > T:\development\ARC_EXP_TEMPLATE\public\models\Skybox\Skybox-transformed-transformed.glb [4.31KB] (0%)
*/

import {
  Mesh,
  MeshBasicMaterial,
  MeshNormalMaterial,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  SRGBColorSpace,
} from "three";
// import { useControls } from "leva";
import { useGLTF, useTexture, Environment } from "@react-three/drei";
import { GLTF } from "three-stdlib";

const __DEBUG = false;

type GLTFResult = GLTF & {
  nodes: {
    UI: Mesh;
  };
  materials: { [name: string]: MeshStandardMaterial };
};

export function Skybox(props: JSX.IntrinsicElements["group"]) {
  // let envControls = { intensity: 2.5 };
  // const levaControls = useControls("enviornment", {
  //   background_visibility: {
  //     value: true,
  //   },
  //   intensity: {
  //     value: 2.5,
  //     min: 0,
  //     max: 50,
  //     step: 0.1,
  //   },
  // });
  // if (__DEBUG) {
  //   envControls = levaControls;
  // }

  const { nodes } = useGLTF("/models/Skybox/Skybox.glb") as GLTFResult;
  if (__DEBUG) {
    console.log("SKYBOX");
    console.log(nodes);
  }
  const albedoTexture = useTexture("/models/Skybox/Universe@0.75x.jpg");
  albedoTexture.colorSpace = SRGBColorSpace;
  albedoTexture.flipY = false;
  const materialsGenArc = {
    Back_01: new MeshStandardMaterial({
      color: 0x000000,
      metalness: 0,
      roughness: 0.5,
      transparent: true,
      opacity: 0.5,
    }),
    Normal: new MeshNormalMaterial({}),
    Physical: new MeshPhysicalMaterial({
      color: 0x808080,
      metalness: 0,
      roughness: 0.5,
      transparent: false,
      opacity: 1,
    }),
    Basic: new MeshBasicMaterial({
      map: albedoTexture,
      transparent: false,
      opacity: 1,
    }),
  };
  // const envIntensity = envControls.intensity;
  const envIntensity = 2.5;

  return (
    <group {...props} dispose={null}>
      <Environment background={false}>
        <color args={["#000000"]} />
        <mesh position-z={-5} scale={10}>
          <planeGeometry />
          <meshBasicMaterial
            color={[
              0.2839 * envIntensity,
              0.6653 * envIntensity,
              0.9629 * envIntensity,
            ]}
          />
        </mesh>
      </Environment>
      <mesh geometry={nodes.UI.geometry} material={materialsGenArc.Basic} />
    </group>
  );
}

useGLTF.preload("/models/Skybox/Skybox-transformed.glb");
