/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t --transform Digitarium.glb 
Files: Digitarium.glb [6.77KB] > C:\Users\fedde\Downloads\Digitarium-transformed.glb [6.64KB] (2%)
*/

import { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { CalloutProps } from "../../components/Callout";
import { Icon, IconProps } from "../../components/Icon";
import {
  Mesh,
  MeshNormalMaterial,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  Vector3,
} from "three";

type GLTFResult = GLTF & {
  nodes: {
    WebExp_Digitarium: Mesh;
  };
  materials: { [name: string]: MeshStandardMaterial };
};

export function Digitarium(props: JSX.IntrinsicElements["group"]) {
  const calloutProps: CalloutProps = {
    graphicTexturePath: "/images/IconGraphic.png",
    graphicGlb: "/models/Callout/CalloutPlanev02.glb",
    graphicLocation: new Vector3(-0.59, -0.1, 1.1),
    textTitle: "Digitarium",
    textTitleFont: "/fonts/bebas-neue-v9-latin-regular.woff",
    textTitleColor: "#ffffff",
    textTitleFontSize: 0.4,
    textTitlePosition: new Vector3(-0.6, 0, 1),
    textSubTitle: "Digital Planetarium",
    textSubTitleFontSize: 0.2,
    textSubTitleFont: "/fonts/bebas-neue-v9-latin-regular.woff",
    textSubTitleColor: "#00EAFF",
    textSubTitlePosition: new Vector3(-0.61, -0.4, 1.21),
    scaleSpeed: 5,
    pivotPoint: new Vector3(-30, 24.63397, 0),
    active: false,
  };
  const iconProps: IconProps = {
    name: "Digitarium",
    blenderPosition: new Vector3(0, 0, 4.63397),
    idleRotationSpeed: 0.5,
    hoverTranslateY: 5,
    hoverLookAtFactor: 5,
    selectorDimensions: [8, 12, 8],
    paddingLeaveDimensions: [25, 25, 25],
    hoverRotationY: 4,
    cameraPositionSelected: new Vector3(20, 4.63397, 100),
    cameraTargetSelected: new Vector3(0, 4.63397, 0),
    calloutProps: calloutProps,
  };

  //REFS
  const digitariumRef = useRef(null);
  const { nodes } = useGLTF("/models/Digitarium/Digitarium.glb") as GLTFResult;

  //LOAD TEXTURES
  const albedoTexture = useTexture(
    "/models/Digitarium/CatHQ_Digitarium_lowpoly-02_CatHQ_Digitarium_BaseColor@0.5x.jpg"
  );
  albedoTexture.flipY = false;

  const metallicTexture = useTexture(
    "/models/Digitarium/CatHQ_Digitarium_lowpoly-02_CatHQ_Digitarium_Metallic@0.5x.jpg"
  );
  metallicTexture.flipY = false;

  const normalTexture = useTexture(
    "/models/Digitarium/CatHQ_Digitarium_lowpoly-02_CatHQ_Digitarium_Normal@0.5x.jpg"
  );
  normalTexture.flipY = false;

  const emissiveTexture = useTexture(
    "/models/Digitarium/CatHQ_Digitarium_lowpoly-02_CatHQ_Digitarium_Emissive@0.75x.jpg"
  );
  emissiveTexture.flipY = false;

  const roughnessTexture = useTexture(
    "/models/Digitarium/CatHQ_Digitarium_lowpoly-02_CatHQ_Digitarium_Roughness@0.5x.jpg"
  );
  roughnessTexture.flipY = false;

  const materialsGenArc = {
    Back_01: new MeshStandardMaterial({
      color: 0x000000,
      metalness: 0,
      roughness: 0.5,
      transparent: true,
      opacity: 0.5,
    }),
    Normal: new MeshNormalMaterial({}),
    Physical: new MeshPhysicalMaterial({
      map: albedoTexture,
      metalness: 1,
      metalnessMap: metallicTexture,
      roughness: 1,
      roughnessMap: roughnessTexture,
      normalMap: normalTexture,
      emissive: 0xffffff,
      emissiveMap: emissiveTexture,
      emissiveIntensity: 1,
      opacity: 1,
      transparent: false,
    }),
    Surface: new MeshStandardMaterial({
      map: albedoTexture,
    }),
  };
  return (
    <Icon
      name={iconProps.name}
      blenderPosition={iconProps.blenderPosition}
      idleRotationSpeed={iconProps.idleRotationSpeed}
      hoverTranslateY={iconProps.hoverTranslateY}
      hoverLookAtFactor={iconProps.hoverLookAtFactor}
      selectorDimensions={iconProps.selectorDimensions}
      paddingLeaveDimensions={iconProps.paddingLeaveDimensions}
      hoverRotationY={iconProps.hoverRotationY}
      cameraPositionSelected={iconProps.cameraPositionSelected}
      cameraTargetSelected={iconProps.cameraTargetSelected}
      calloutProps={calloutProps}
    >
      <group ref={digitariumRef} {...props} dispose={null}>
        <mesh
          geometry={nodes.WebExp_Digitarium.geometry}
          material={materialsGenArc.Physical}
          rotation={[0, 0.585, 0]}
        />
      </group>
    </Icon>
  );
}

useGLTF.preload("/models/Digitarium/Digitarium.glb");
