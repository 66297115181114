import React, { ReactNode, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { KeyboardControls } from "@react-three/drei";
import { ExperienceStates, useExperience } from "../stores/useExperience";

interface SceneProps {
  children?: ReactNode; // 'children' can be any valid React node
}

export const Scene: React.FC<SceneProps> = (props: SceneProps) => {
  const { experienceState } = useExperience();
  const exit = useExperience((state) => state.exit);
  function handleClosePage() {
    if (experienceState === ExperienceStates.SELECTED) {
      exit({});
    }
  }
  useEffect(() => {
    const canvas = document.getElementById("canvas");
    canvas?.addEventListener("mousedown", handleClosePage);
    return () => {
      canvas?.removeEventListener("mousedown", handleClosePage);
    };
  });
  return (
    <KeyboardControls
      map={[
        { name: "forward", keys: ["KeyW", "ArrowUp"] },
        { name: "backward", keys: ["KeyS", "ArrowDown"] },
        { name: "left", keys: ["KeyA", "ArrowLeft"] },
        { name: "right", keys: ["KeyD", "ArrowRight"] },
        { name: "jump", keys: ["Space"] },
        { name: "down", keys: ["ShiftLeft"] },
      ]}
    >
      <Canvas id="canvas" shadows>
        {props.children}
      </Canvas>
    </KeyboardControls>
  );
};
