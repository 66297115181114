import { Suspense } from "react";
import "./App.css";
import { Scene } from "./components/Scene";
import PageScene from "./components/PageScene";
import { Experience } from "./experience/Experience";
import { loadingScreen } from "./utils/loadingScreen";

function App() {
  return (
    <Suspense fallback={loadingScreen}>
      <Scene>
        <Experience />
      </Scene>
      <PageScene />
    </Suspense>
  );
}

export default App;
