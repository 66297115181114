/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 -t --transform Arm.glb 
Files: Arm.glb [7.12KB] > /Users/nakaikoi/MOTIONTONIC Dropbox/CATBOTICA/CATBOTICA_EXPERIENCES/WEBSITE EXPERIENCE/UI/OUTPUTS/3JS/_Hold/Arm-transformed.glb [7.18KB] (-1%)
*/

import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import {
  Mesh,
  MeshNormalMaterial,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
} from "three";

type GLTFResult = GLTF & {
  nodes: {
    WebExp_Arm: Mesh;
  };
  materials: {
    CatHQ_Arm: MeshStandardMaterial;
  };
};

export function Arm() {
  const { nodes } = useGLTF("/models/Arm-transformed.glb") as GLTFResult;

  const materialsGenArc = {
    Back_01: new MeshStandardMaterial({
      color: 0x000000,
      metalness: 0,
      roughness: 0.5,
      transparent: true,
      opacity: 0.5,
    }),
    Normal: new MeshNormalMaterial({}),
    Physical: new MeshPhysicalMaterial({
      color: 0x808080,
      metalness: 0,
      roughness: 0.5,
      transparent: false,
      opacity: 1,
    }),
  };

  return (
    <group dispose={null}>
      <mesh
        geometry={nodes.WebExp_Arm.geometry}
        material={materialsGenArc.Physical}
        position={[-34.704, -12.386, -18.263]}
        rotation={[0, -Math.PI / 6, 0]}
      />
      <mesh
        geometry={nodes.WebExp_Arm.geometry}
        material={materialsGenArc.Physical}
        position={[-2.101536273956299, -11.58448600769043, -40.61599349975586]}
        rotation={[0, -82.4395 * (Math.PI / 180), 0]}
      />

      <mesh
        geometry={nodes.WebExp_Arm.geometry}
        material={materialsGenArc.Physical}
        position={[
          34.929508209228516, -12.315895080566406, -26.967082977294922,
        ]}
        rotation={[0, -130.56 * (Math.PI / 180), 0]}
      />

      <mesh
        geometry={nodes.WebExp_Arm.geometry}
        material={materialsGenArc.Physical}
        position={[41.75613021850586, -12.17301082611084, 24.578243255615234]}
        rotation={[0, -248.473 * (Math.PI / 180), 0]}
      />

      <mesh
        geometry={nodes.WebExp_Arm.geometry}
        material={materialsGenArc.Physical}
        position={[-1.3324837684631348, -12.228273391723633, 39.57130432128906]}
        rotation={[0, -284.148 * (Math.PI / 180), 0]}
      />
    </group>
  );
}

useGLTF.preload("/models/Arm-transformed.glb");
