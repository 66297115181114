import React from "react";
import { PageProps } from "../../types/page";
import { ExitButton } from "../../components/ExitButton";

export const ReplicatsBuilderPage: React.FC<PageProps> = ({ onPageExit }) => {
  const title = "Replicats Builder";
  const video = "https://www.youtube.com/embed/aOQPLFJnxCY?si=mI92L1YpqNMXWwYJ";
  const videoWidth = String(560 / 2);
  const videoHeight = String(315 / 2);

  return (
    <div className="page-wrapper">
      <div className="page">
        <h1>{title}</h1>
        <ExitButton onPageExit={onPageExit} />
        <div className="page-content">
          <div className="content-section">
            <h2>Replicats are 3D versions of genesis 2D Catbots</h2>
            <p className="page-p">
              Fully rigged and ready for the metaverse. Each 2D Catbot you own
              allows you to generate one Replicat with the burning of one NV1
              VATPACK. Replicats inherit all traits from your 2D Catbot. The
              base from your 2D Catbot becomes your Replicat’s surface.
            </p>
            <img className="page-img" src="/images/2d3d.png" />
          </div>
          <div className="content-section">
            <h2>Hold. Play. Upgrade.</h2>
            <p className="page-p">
              Your Replicat is crafted for the metaverse. Games, socials, and
              AR/VR integration in the works. Combine five Replicats to form a
              Replicatron, a larger mecha-catbot able to perform, survive and
              excel under more extreme conditions both in planetary and deep
              space environments.
            </p>
            <h3 className="receive-test">You will receive:</h3>

            <div className="receive-list-wrapper">
              <div className="file-info-box">
                <img
                  src="/images/replicat-headshot.png"
                  alt="Replicat headshot"
                  className="replicat-receive-img"
                />
                <p className="headshot-text">Headshot</p>
                <p className="file-text">JPG File</p>
              </div>
              <div className="file-info-box">
                <img
                  draggable="false"
                  src="/images/replicat-bodyshot.png"
                  alt="Replicat bodyshot"
                  className="replicat-receive-img"
                />
                <p className="headshot-text">Body shots</p>
                <p className="file-text">JPG File</p>
              </div>
              <div className="file-info-box">
                <svg
                  width="128"
                  height="128"
                  viewBox="0 0 128 128"
                  className="replicat-receive-img"
                >
                  <g
                    id="Group_596"
                    data-name="Group 596"
                    transform="translate(-1000 -2180)"
                  >
                    <rect
                      id="Rectangle_362"
                      data-name="Rectangle 362"
                      width="128"
                      height="128"
                      rx="16"
                      transform="translate(1000 2180)"
                      fill="#1b1b23"
                    ></rect>
                    <path
                      id="Rectangle_362_-_Outline"
                      data-name="Rectangle 362 - Outline"
                      d="M16,1A15,15,0,0,0,1,16v96a15,15,0,0,0,15,15h96a15,15,0,0,0,15-15V16A15,15,0,0,0,112,1H16m0-1h96a16,16,0,0,1,16,16v96a16,16,0,0,1-16,16H16A16,16,0,0,1,0,112V16A16,16,0,0,1,16,0Z"
                      transform="translate(1000 2180)"
                      fill="#48ffeb"
                    ></path>
                    <path
                      id="icons8-play"
                      d="M7,3A2,2,0,0,0,5,5s0,.005,0,.008V48.992S5,49,5,49a2,2,0,0,0,3.16,1.625l0,0L47.832,28.816a2,2,0,0,0-.055-3.66L8.164,3.371h0A2,2,0,0,0,7,3Z"
                      transform="translate(1044 2217)"
                      fill="#48ffeb"
                    ></path>
                  </g>
                </svg>
                <p className="headshot-text">Video</p>
                <p className="file-text">MP4 File</p>
              </div>
              <div className="file-info-box">
                <svg
                  width="128"
                  height="128"
                  viewBox="0 0 128 128"
                  className="replicat-receive-img"
                >
                  <g
                    id="Group_598"
                    data-name="Group 598"
                    transform="translate(-1136 -2180)"
                  >
                    <rect
                      id="Rectangle_360"
                      data-name="Rectangle 360"
                      width="128"
                      height="128"
                      rx="16"
                      transform="translate(1136 2180)"
                      fill="#1b1b23"
                    ></rect>
                    <path
                      id="Rectangle_360_-_Outline"
                      data-name="Rectangle 360 - Outline"
                      d="M16,1A15,15,0,0,0,1,16v96a15,15,0,0,0,15,15h96a15,15,0,0,0,15-15V16A15,15,0,0,0,112,1H16m0-1h96a16,16,0,0,1,16,16v96a16,16,0,0,1-16,16H16A16,16,0,0,1,0,112V16A16,16,0,0,1,16,0Z"
                      transform="translate(1136 2180)"
                      fill="#48ffeb"
                    ></path>
                    <path
                      id="icons8-octaedro"
                      d="M39.416,1.5q-.071,0-.142.006-.054,0-.108.012a1.582,1.582,0,0,0-.2.043q-.066.02-.13.046l-.025.009-.083.037-.059.028-.059.034-.074.043-.012.009q-.059.039-.114.083t-.093.083l-.025.022-.006.006-.136.136a1.582,1.582,0,0,0-.334.334L20.491,19.755,2.7,37.546q-.055.056-.105.117a1.582,1.582,0,0,0-.1.127q-.044.066-.08.136a1.64,1.64,0,0,0-.154.448q-.015.077-.022.154a1.583,1.583,0,0,0-.006.161q0,.062.006.124,0,.025.006.049.009.07.025.139t.034.127l.012.04q.023.068.053.133t.053.111l.025.046q.036.059.077.114s0,0,0,0q.028.04.059.077t.062.074l.009.012.043.043q.033.035.068.068l35.4,35.4a1.582,1.582,0,0,0,2.548-.093L73.3,40.289l0,0a1.582,1.582,0,0,0,.46-.491l.046-.049a1.573,1.573,0,0,0,0-2.156l0-.006L40.556,2l-.009-.009-.006,0a1.582,1.582,0,0,0-.222-.195l-.012-.009q-.058-.041-.12-.077L40.173,1.7q-.063-.035-.13-.065l-.012-.006q-.067-.028-.136-.049t-.114-.034l-.037-.009-.015,0-.015,0q-.069-.014-.139-.022T39.416,1.5ZM38.5,7.656l5.717,23.324L26.885,27.051Zm3.348.364L68.439,36.471l-20.772-4.71ZM31.459,13.259,23.2,27.041,11.969,32.749,22.727,21.991ZM26.811,30.275l18.249,4.136L48.92,50.167l-17.3-5.044Zm-3.277.145,4.417,13.634L8.1,38.263Zm24.973,4.772,19.679,4.463L52.127,49.963ZM9.3,41.911l19.83,5.782,6.737,20.785Zm53.554,4.924L46.477,64.357l5.708-10.675ZM32.8,48.762,48.744,53.41,39.759,70.223Z"
                      transform="translate(1161.764 2205.324)"
                      fill="#48ffeb"
                    ></path>
                  </g>
                </svg>
                <p className="headshot-text">3D Model</p>
                <p className="file-text">GLB File</p>
              </div>
            </div>
          </div>
          <div className="content-section">
            <h2>How to mint a Replicat?</h2>
            <iframe
              width={videoWidth}
              height={videoHeight}
              src={video}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <p>
              In order to mint your 3D Replicat, you need a wallet, like
              MetaMask, with at least one 2D Catbot, at least one NV1 VATPACK,
              and enough Ethereum to cover gas fees. The NV1 will be burned, the
              genesis Catbot will remain and a REPLICAT will appear in your
              wallet.
            </p>
            <h2>
              <a
                className="page-button"
                href="https://replicats.catbotica.com/"
              >
                Mint Now
              </a>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};
